<style lang="scss">
@import "~@/assets/css/var.scss";
.report-page {
  width: 1080px;
  margin: 50px auto;
  &__search {
    padding: 0 15px;
    border-top: 1px solid #ccc;
    background: #f8f8f8;
    .title {
      display: flex;
      line-height: 45px;
      border-bottom: 1px solid #ccc;
      .main {
        color: $main;
        font-weight: bold;
      }
    }

    .item {
      display: flex;
      align-items: flex-start;
      line-height: 45px;
      border-bottom: 1px dashed #ccc;

      &:last-child {
        border: 0;
      }
      .el-badge__content {
        right: 5px;
        font-size: 10px;
      }
      .head {
        text-align: right;
        width: 60px;
        font-weight: 600;
      }

      span {
        display: inline-block;
        line-height: 20px;
        border-radius: 10px;
        padding: 3px 10px;
        margin: 0 5px;
        cursor: pointer;
        &.active, &:hover {
          background: $main;
          color: #fff;
        }
        &.active {
          cursor: default;
        }
      }
    }
  }

  &__body {
    margin-top: 40px;
    .title {
      display: flex;
      align-items: center;
      background: #f1f1f1;
      span {
        display: inline-block;
        border-radius: 0 16px 16px 0;
        line-height: 32px;
        font-size: 14px;
        background: $main;
        color: #fff;
        padding: 0 15px 0 40px;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    padding: 40px 0 40px 40px;
    border-bottom: 1px solid #ccc;
    .img-title {
      position: absolute;
      top: 64px;
      left: 52px;
      width: 70px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #fff;
    }
    .img {
      width: 120px;
      margin-right: 60px;
      cursor: pointer;
    }
    .recommend {
      position: absolute;
      top: 38px;
      left: 0;
      background: $main;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      font-style: normal;
      padding: 0 5px;
      border-radius: 0 0 5px 0;
    }
    .content {
      flex: 1;
      overflow: hidden;
    }
    .name {
      color: #333;
      font-size: 16px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .desc {
      padding-top: 20px;
      color: #999;
      font-size: 14px;
      text-indent: 24px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      overflow: hidden;
    }
    .type {
      display: flex;
      padding-top: 20px;
      color: #999;
      i {
        padding-right: 5px;
      }
      span {
        flex: 1;
      }
    }
  }
  .el-pagination {
    text-align: center;
    margin-top: 30px;
  }
}
</style>

<template>
    <div class="report-page">
        <div class="report-page__search">
            <div class="title">
                <span class="main">报告大厅：</span>
                <span style="flex: 1;"></span>
<!--                <span style="flex: 1;">{{total}}个</span>-->
                <div>
                    <el-input v-model="txt" placeholder="请输入报告名称搜索" style="width: 260px" clearable
                              @clear="txtSearch" @keyup.native.enter="txtSearch">
                        <el-button slot="append" icon="el-icon-search" @click="txtSearch"></el-button>
                    </el-input>
                </div>
                <!--<div><el-button v-if="$help.token.get()" type="primary" @click="goUpload">自助报告</el-button></div>-->
            </div>
            <div class="item" v-for="item in headList" :key="item.key">
                <p class="head">{{item.name}}：</p>
                <div class="flex-item">
                    <span :class="{'active': search[item.key] === ''}" @click="searchChange(item.key, '')">
                        <el-badge :value="item.key === 'reportCategory' ? (headMap.all ? headMap.all : '') : ''">
                          全部
                        </el-badge>
                    </span>
                    <span v-for="child in item.children" :key="child" :class="{'active': search[item.key] === child}"
                          @click="searchChange(item.key, child)">
                        <el-badge :hidden="item.key !== 'reportCategory'" :value="headMap[child] || ''">
                          {{ child }}
                        </el-badge>

                    </span>
                </div>
            </div>
        </div>

        <div class="report-page__body" v-loading="loading">
            <p class="title"><span>全部报告（{{total}}）</span></p>
            <div class="report-page__item" v-for="item in list" :key="item.id">
                <i class="recommend" v-if="item.recommend === '是'">推荐</i>
                <p class="img-title" :title="item.title">{{item.title}}</p>
                <img class="img" src="@/assets/img/report.png" alt="" @click="goPage(item)">
                <div class="content">
                    <p class="name" @click="goPage(item)">{{item.title}}</p>
                    <p class="desc">{{item.description}}</p>
                    <div class="type">
                        <span><i class="el-icon-download"></i>下载次数：{{item.countDown}}</span>
                        <span><i class="el-icon-mobile-phone"></i>浏览次数：{{item.countVisit}}</span>
                        <span><i class="el-icon-price-tag"></i>页数：{{item.countPage}}</span>
                        <span><i class="el-icon-shopping-cart-2"></i>价格：{{item.price}}</span>
                        <span v-if="item.author"><i class="el-icon-user"></i>作者：{{item.author}}</span>
                        <span style="flex: unset;padding-right: 15px;"><i class="el-icon-bell"></i>上传时间：{{item.createdAt.FormatShort()}}</span>
                    </div>
                </div>
            </div>

            <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'report',
            pageName: '报告大厅',


            txt: '',
            txtTitle: '',
            headList: [],
            headMap: {},
            headLoading: true,
            search: {},
            params: {},

            total: 0,
            list: [],
            page: 1,
            pageSize: 10,
            loading: false,
        }
    },
    created() {
        this.$api.get('report/aimReportFile/filterField')
            .then(res => {
                // this.headList = res.data
                for(let i in res.data){
                    if(i === 'aimReportFileCategoryList'){
                        let children = []
                        res.data[i].forEach(ele=>{
                            children.push(ele.value)
                        })
                        this.headList.push({
                            children: children,
                            key: "category",
                            name: "产品类型"
                        })
                    }
                    if(i === 'aimReportFileFeeList'){
                        let children = []
                        res.data[i].forEach(ele=>{
                            children.push(ele.value)
                        })
                        this.headList.push({
                            children: children,
                            key: "fees",
                            name: "收费标准"
                        })
                    }
                    if(i === 'aimReportFileReportCategoryList'){
                        let children = []
                        res.data[i].forEach(ele=>{
                            children.push(ele.value)
                        })
                        this.headList.push({
                            children: children,
                            key: "reportCategory",
                            name: "报告类型"
                        })
                    }
                }
                this.headList.forEach(item => {
                    this.$set(this.search, item.key, '')
                })
            })
            .finally(() => {
                this.headLoading = false
            })
        this.getData()

        this.$help.socket.send(this)
    },
    methods: {
        txtSearch() {
            this.txtTitle = this.txt
            this.page = 1
            this.getData()
        },
        searchChange(key, value) {
            this.search[key] = value
            if(value !== ''){
                this.params[key] = value
            }else{
                delete this.params[key]
            }
            this.page = 1
            this.getData()
        },
        getData() {
            this.loading = true
            const param = this.$help.deepClone(this.params)

            const conditionList = []
            if (this.txtTitle) {
                conditionList.push({
                    name: "title",
                    op: "like",
                    value: this.txtTitle
                })
            }

            this.headMap = {}
            this.$api.post('report/aimReportFile/queryAimReportFilePage', {
                params: param,
                conditionList,
                page: {pageNo: this.page, pageSize: this.pageSize},
                sort: {createdAt: 'desc'},
            }, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                .then(res => {
                    this.total = res.data.totalCount
                    this.list = res.data.records

                    // 统计信息
                    if (res.data.sumData && res.data.sumData.aimReportFileReportCategoryList && res.data.sumData.aimReportFileReportCategoryList.length > 0) {
                        let total = 0
                        res.data.sumData.aimReportFileReportCategoryList.forEach(item => {
                            this.$set(this.headMap, item.value, item.number)
                            total += item.number
                        })
                        this.$set(this.headMap, 'all', total)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        pageChange(val) {
            this.page = val
            this.getData()
        },
        goPage(item) {
            const { href } = this.$router.resolve({
                path: `/report/${item.id}`,
            });
            window.open(href, "_blank");
        },
        goUpload() {
            this.$router.push({
                path: 'report/self',
            });
        },
    },
}
</script>